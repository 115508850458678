<template>
  <div class="home">
    <v-row no-gutters>
      <v-col cols="7">
        <div @click="goBack()" class="mt-7 go-back">
          <img src="../assets/Images/back-black.svg" alt="cart" />
        </div>
        <div class="mt-3 font-30 butler-700">Create Customization - Step 3</div></v-col
      >
      <v-col cols="5">
        <div class="mt-6 mb-3 ml-8 px-16">
          <p class="text-black mb-2">Select Status</p>
          <v-btn-toggle v-model="selectedStatus" dense color="black" class="select-color">
            <v-btn value="draft"> Draft </v-btn>

            <v-btn value="approve"> Approve </v-btn>

            <v-btn value="publish"> Publish </v-btn>
          </v-btn-toggle>
        </div>
      </v-col>
    </v-row>
    <v-card>
      <div class="container">
        <div class="row">
          <div class="col-6">
            <div class="category-tree-2">
              <h5>Color Customization</h5>
              <ul>
                <li
                  style="display: block"
                  class="text-capitalize"
                  v-for="(category, index) in categories"
                  v-bind:key="index"
                >
                  {{ category.name }}

                  <ul>
                    <li
                      style="display: block"
                      v-for="(layer, index) in category.layers"
                      v-bind:key="index"
                      class="text-capitalize"
                    >
                      {{ layer.name }}
                      <span class="sub-text">- Change of {{ layer.typeOfLayer }}</span>
                      &nbsp;

                      <span
                        ><button
                          class="btn btn-small btn-outline-primary show-button"
                          v-if="layer.typeOfLayer != 'Image' && layer.typeOfLayer != 'Embroidery'"
                          @click="toggleLayerShow(category, layer)"
                        >
                          Color Mapping
                        </button></span
                      >
                      <div
                        v-if="
                          layer.typeOfLayer != 'Image' &&
                          layer.typeOfLayer != 'Embroidery' &&
                          layer.typeOfLayer != 'Embroidery Color' &&
                          layer.show
                        "
                      >
                        <div class="">
                          <div class="padding-10"></div>
                          <div>
                            <h6>Color Mapping Per Layer</h6>
                            <table class="table table-bordered">
                              <thead>
                                <tr>
                                  <th>Sr. No.</th>
                                  <th>Category</th>
                                  <th>Layer</th>
                                  <th>Color</th>
                                  <!-- <th>Base Type</th> -->
                                </tr>
                              </thead>
                              <!-- <tbody v-for="(category1, index) in categories" v-bind:key="index">
                                <tr v-for="(layer1, index) in category1.layers" v-bind:key="index"> -->

                              <tbody>
                                <tr
                                  v-for="(mapValue, index) in layer.colorMapping"
                                  v-bind:key="index"
                                >
                                  <td>{{ index + 1 }}</td>
                                  <td>{{ getCategoryNameFromLayerId(mapValue.layerId) }}</td>
                                  <td>{{ mapValue.name }}</td>
                                  <td>
                                    <select class="form-control" id="model" v-model="mapValue.id">
                                      <option value="">None</option>
                                      <option :value="'1_' + customization._id">Group 1</option>
                                      <option :value="'2_' + customization._id">Group 2</option>
                                      <option :value="'3_' + customization._id">Group 3</option>
                                      <option :value="'4_' + customization._id">Group 4</option>
                                      <option :value="'5_' + customization._id">Group 5</option>
                                      <option :value="'6_' + customization._id">Group 6</option>
                                      <option :value="'7_' + customization._id">Group 7</option>
                                      <option :value="'8_' + customization._id">Group 8</option>
                                      <option :value="'9_' + customization._id">Group 9</option>
                                      <option :value="'10_' + customization._id">Group 10</option>
                                      <option :value="'11_' + customization._id">Group 11</option>
                                      <option :value="'12_' + customization._id">Group 12</option>
                                      <option :value="'13_' + customization._id">Group 13</option>
                                      <option :value="'14_' + customization._id">Group 14</option>
                                      <option :value="'15_' + customization._id">Group 15</option>
                                      <option :value="'16_' + customization._id">Group 16</option>
                                      <option :value="'17_' + customization._id">Group 17</option>
                                      <option :value="'18_' + customization._id">Group 18</option>
                                      <option :value="'19_' + customization._id">Group 19</option>
                                      <option :value="'20_' + customization._id">Group 20</option>
                                    </select>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>

                      <div v-if="layer.typeOfLayer == 'Embroidery Color' && layer.show">
                        <div class="">
                          <div class="padding-10"></div>

                          <div>
                            <h6>Embroidery Color Mapping Per Layer</h6>
                            <table class="table table-bordered">
                              <thead>
                                <tr>
                                  <th>Sr. No.</th>
                                  <th>Category</th>
                                  <th>Layer</th>
                                  <th>Color</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="(mapValue, index) in layer.colorMapping"
                                  v-bind:key="index"
                                >
                                  <td>{{ index + 1 }}</td>
                                  <td>{{ getCategoryNameFromLayerId(mapValue.layerId) }}</td>
                                  <td>{{ mapValue.name }}</td>
                                  <td>
                                    <select class="form-control" id="model" v-model="mapValue.id">
                                      <option value="">None</option>
                                      <option :value="'1_' + customization._id">Group 1</option>
                                      <option :value="'2_' + customization._id">Group 2</option>
                                      <option :value="'3_' + customization._id">Group 3</option>
                                      <option :value="'4_' + customization._id">Group 4</option>
                                      <option :value="'5_' + customization._id">Group 5</option>
                                      <option :value="'6_' + customization._id">Group 6</option>
                                      <option :value="'7_' + customization._id">Group 7</option>
                                      <option :value="'8_' + customization._id">Group 8</option>
                                      <option :value="'9_' + customization._id">Group 9</option>
                                      <option :value="'10_' + customization._id">Group 10</option>
                                      <option :value="'11_' + customization._id">Group 11</option>
                                      <option :value="'12_' + customization._id">Group 12</option>
                                      <option :value="'13_' + customization._id">Group 13</option>
                                      <option :value="'14_' + customization._id">Group 14</option>
                                      <option :value="'15_' + customization._id">Group 15</option>
                                      <option :value="'16_' + customization._id">Group 16</option>
                                      <option :value="'17_' + customization._id">Group 17</option>
                                      <option :value="'18_' + customization._id">Group 18</option>
                                      <option :value="'19_' + customization._id">Group 19</option>
                                      <option :value="'20_' + customization._id">Group 20</option>
                                    </select>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <ul>
                        <li
                          style="display: block"
                          v-for="(toggle, index) in layer.toggles"
                          v-bind:key="index"
                          class="text-capitalize mt-1"
                        >
                          {{ toggle.name }}
                          <span
                            class="show-button"
                            v-if="
                              layer.typeOfLayer != 'Image' &&
                              layer.colorMapping &&
                              layer.colorMapping.length > 0 &&
                              !layer.show
                            "
                          >
                            >
                            <button
                              type="button"
                              @click="toggleToggleShowForColor(layer, toggle)"
                              class="btn btn-outline-info btn-sm"
                            >
                              <span v-show="!toggle.show">Show</span>
                              <span v-show="toggle.show">Hide</span>
                            </button></span
                          >
                          <div v-if="toggle.colorAssignment.length > 0">
                            <div class="category-section" v-if="toggle.show">
                              <div>
                                <h5>Customize Color for {{ toggle.name }}</h5>

                                <div>
                                  <h6>Base Mapping Per Color</h6>
                                  <table class="table table-bordered">
                                    <thead>
                                      <tr>
                                        <th>Sr. No.</th>
                                        <th>Category</th>
                                        <th>Layer</th>
                                        <th>Base Type</th>
                                      </tr>
                                    </thead>

                                    <tbody>
                                      <tr
                                        v-for="(mapValue, index) in layer.colorMapping"
                                        v-bind:key="index"
                                      >
                                        <td>{{ index + 1 }}</td>
                                        <td>{{ getCategoryNameFromLayerId(mapValue.layerId) }}</td>
                                        <td>{{ mapValue.name }}</td>

                                        <td>
                                          <div
                                            v-for="(category, categoryIndex) in categories"
                                            v-bind:key="categoryIndex"
                                          >
                                            <div
                                              v-for="(layer, layerIndex) in category.layers"
                                              v-bind:key="layerIndex"
                                            >
                                              <div v-if="layer.id === mapValue.layerId">
                                                <div
                                                  v-for="(
                                                    toggleInside, toggleIndex
                                                  ) in layer.toggles"
                                                  v-bind:key="toggleIndex"
                                                >
                                                  <div>
                                                    {{ toggleInside.name }}
                                                  </div>
                                                  <div>
                                                    Selected -{{
                                                      getBaseNameByToggleId(
                                                        toggleInside._id,
                                                        toggle
                                                      )
                                                    }}
                                                  </div>

                                                  <v-select
                                                    :items="
                                                      getBaseTypesForLayer(
                                                        mapValue.layerId,
                                                        toggleInside
                                                      )
                                                    "
                                                    item-text="name"
                                                    item-value="id"
                                                    label="Select base"
                                                    solo
                                                    color="grey"
                                                    v-on:change="
                                                      changeBaseType(
                                                        mapValue,
                                                        toggle,
                                                        $event,
                                                        toggleInside._id
                                                      )
                                                    "
                                                    append-icon="▼"
                                                  >
                                                  </v-select>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                <div
                                  class="row layer-section"
                                  v-for="colorNumber in toggle.colorAssignment"
                                  :key="colorNumber.index"
                                >
                                  <h6>Group {{ getGroupName(colorNumber.colorMappingId) }}</h6>
                                  <div class="col-md-6" align="left">
                                    <label for="customRange2" class="form-label text-left"
                                      >Saturate
                                    </label>
                                    <v-text-field
                                      v-model="colorNumber.colorObject.saturation"
                                      outlined
                                      width="20"
                                      dense
                                      >{{ colorNumber.colorObject.saturation }}</v-text-field
                                    >

                                    <input
                                      type="range"
                                      class="form-range"
                                      min="0"
                                      max="100"
                                      step="0.1"
                                      v-model="colorNumber.colorObject.saturation"
                                      @change="onColorChange()"
                                    />
                                  </div>
                                  <div class="col-md-6" align="left">
                                    <label for="customRange2" class="form-label">Hue-rotate </label>
                                    <v-text-field
                                      v-model="colorNumber.colorObject.hue"
                                      outlined
                                      width="20"
                                      dense
                                    >
                                      {{ colorNumber.colorObject.hue }}</v-text-field
                                    >

                                    <input
                                      type="range"
                                      class="form-range"
                                      min="0"
                                      max="360"
                                      step="0.1"
                                      id="customRange2"
                                      v-model="colorNumber.colorObject.hue"
                                      @change="onColorChange()"
                                    />
                                  </div>
                                  <div class="col-md-6" align="left">
                                    <label for="customRange2" class="form-label">Brightness </label>
                                    <v-text-field
                                      v-model="colorNumber.colorObject.brightness"
                                      outlined
                                      width="20"
                                      dense
                                    >
                                      {{ colorNumber.colorObject.brightness }}</v-text-field
                                    >

                                    <input
                                      type="range"
                                      class="form-range"
                                      min="0"
                                      max="1"
                                      step="0.01"
                                      id="customRange2"
                                      v-model="colorNumber.colorObject.brightness"
                                      @change="onColorChange()"
                                    />
                                  </div>
                                  <div class="col-md-6" align="left">
                                    <label for="customRange2" class="form-label">Opacity </label>
                                    <v-text-field
                                      v-model="colorNumber.colorObject.opacity"
                                      outlined
                                      width="20"
                                      dense
                                    >
                                      {{ colorNumber.colorObject.opacity }}</v-text-field
                                    >

                                    <input
                                      type="range"
                                      class="form-range"
                                      min="0"
                                      max="1"
                                      step="0.01"
                                      id="customRange2"
                                      v-model="colorNumber.colorObject.opacity"
                                      @change="onColorChange()"
                                    />
                                  </div>
                                </div>
                                <div class="padding-10"></div>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          <div v-if="customizationsLoaded" class="col-6 model-fixed">
            <CustomizationModel :customization="customization" :show="true" />
          </div>
        </div>
        <div class="d-flex">
          <div class="next-button-section mr-4">
            <div @click="updateData('Save')" class="btn btn-success btn-lg btn-block">Save</div>
          </div>
          <div class="next-button-section">
            <div @click="updateData('Next')" class="btn btn-success btn-lg btn-block">Next</div>
          </div>
        </div>

        <!-- <div v-if="productData">
        <a :href="frontEndUrl + 'customization?productId=' + productData.product._id"
          >Check link for product customization</a
        >
      </div> -->
      </div>
    </v-card>
  </div>
</template>

<script>
import _ from 'lodash';
import service from '../service/apiService';
import CustomizationModel from '@/components/customization/CustomizationModel.vue';

// @ is an alias to /src

export default {
  name: 'Home',
  components: { CustomizationModel },
  data() {
    return {
      customizationsLoaded: false,
      customization: {},
      frontEndUrl: process.env.VUE_APP_FRONT_END_URL,
      saturation: '',
      hue: '',
      brightness: '',
      opacity: '',
      colorSelection: '',
      embroiderySelection: '',
      productData: {},
      categories: [],
      model: ['Male', 'Female'],
      embroideries: [],
      bodytypes: ['Front', 'Back', 'Plus Front', 'Plus Back'],
      imageTypes: ['Core', 'Shading'],
      colors: ['Color 1', 'Color 2', 'Color 3', 'Color 4', 'Color 5'],
      selectedStatus: 'draft',
      dialog: false,
    };
  },
  methods: {
    findEmbForEmbColor(category) {
      const finalLayer = _.find(category.layers, (n) => {
        const obj = n.typeOfLayer === 'Embroidery';
        return obj;
      });
      return finalLayer.embroideryMapping;
    },
    onColorChange() {
      // if (type === 'saturation') {
      //   this.saturation = evt.target.value;
      // } else if (type === 'hue') {
      //   this.hue = evt.target.value;
      // } else if (type === 'brightness') {
      //   this.brightness = evt.target.value;
      // } else {
      //   this.opacity = evt.target.value;
      // }
      this.$store.commit('setCustomization', this.customization);
    },
    uniqueColors(colorMapping) {
      // {
      //     colorMappingId: { type: Number },
      //     colorObject: {
      //         saturation: {
      //             type: Number,
      //             default: 0
      //         },
      //         hue: { type: Number, default: 0 },
      //         brightness: {
      //             type: Number,
      //             default: 0
      //         },
      //         opacity: {
      //             type: Number,
      //             default: 0
      //         }
      //     }
      // }
      /* eslint-disable */
      return _.chain(colorMapping).map('id').compact().uniq().orderBy().value();
    },

    getBaseNameById(id) {
      var name = '';
      _.each(this.categories, (category) => {
        _.each(category.layers, (layer) => {
          _.each(layer.toggles, (toggle) => {
            _.each(toggle.baseTypes, (baseType) => {
              if (baseType.id == id) {
                name = baseType.name;
              }
            });
          });
        });
      });
      return name;
    },

    getBaseNameByToggleId(toggleId, colorToggle) {
      var baseId = '';
      _.each(colorToggle.baseTypesId, (baseType) => {
        if (baseType.toggleId == toggleId) {
          baseId = baseType.baseTypeId;
        }
      });
      var name = {};
      _.each(this.categories, (category) => {
        _.each(category.layers, (layer) => {
          _.each(layer.toggles, (toggle) => {
            _.each(toggle.baseTypes, (baseType) => {
              if (baseType.id == baseId) {
                name = baseType;
              }
            });
          });
        });
      });
      if (name.name == undefined) {
        _.each(this.categories, (category) => {
          _.each(category.layers, (layer) => {
            _.each(layer.toggles, (toggle) => {
              name = toggle.baseTypes[0];
            });
          });
        });
      }
      return name.name;
    },

    toggleLayerShow(category, layer) {
      const oldValue = layer.show;
      _.each(category.layers, (n) => {
        const obj = n;
        obj.show = false;
      });
      const finalLayer = _.find(category.layers, (n) => {
        const obj = n === layer;
        return obj;
      });
      finalLayer.show = !oldValue;

      // if (_.isEmpty(finalLayer.colorMapping)) {
      //   finalLayer.colorMapping = _.map(this.categories, (n) => {
      //     return {
      //       id: '',
      //       layerId: n.id,
      //       name: n.name,
      //     };
      //   });
      // } else {
      //   this.assignLayerValueToColorMapping(layer);
      // }

      finalLayer.colorMapping = this.assignLayerValueToColorMapping(layer);

      // On Color Mapping Save
      if (oldValue && !_.isEmpty(finalLayer.colorMapping)) {
        _.each(layer.toggles, (toggle) => {
          const obj = toggle;
          const uniqueColors = this.uniqueColors(finalLayer.colorMapping);
          var uniqueColorsInt = [];
          _.map(uniqueColors, (uc) => {
            if (Number.isInteger(uc)) {
              uniqueColorsInt.push(uc);
            } else {
              uniqueColorsInt.push(uc);
            }
          });
          obj.colorAssignment = _.map(_.union(uniqueColorsInt), (uniqueColor) => {
            const oldObj = _.find(obj.colorAssignment, (n) => {
              const obj = n.colorMappingId === uniqueColor;
              return obj;
            });
            if (oldObj) {
              return oldObj;
            } else {
              return {
                colorMappingId: uniqueColor,
                colorObject: {
                  saturation: '0',
                  hue: '0',
                  brightness: '0',
                  opacity: '100',
                },
              };
            }
          });
        });
      }
      this.$forceUpdate();
    },
    toggleToggleShowForColor(layer, toggle) {
      const oldValue = toggle.show;
      _.each(layer.toggles, (n) => {
        const obj = n;
        obj.show = false;
      });
      const finalLayer = _.find(layer.toggles, (n) => {
        const obj = n === toggle;
        return obj;
      });
      finalLayer.show = !oldValue;
      this.$forceUpdate();
    },
    getBaseTypesForLayer(layerId, toggleInside) {
      var finalArray = [];
      _.each(this.categories, (category) => {
        _.each(category.layers, (layer) => {
          if (layer.id == layerId) {
            _.each(layer.toggles, (toggle) => {
              if (toggleInside._id === toggle._id) {
                _.each(toggle.baseTypes, (baseType) => {
                  finalArray.push(baseType);
                  console.log(baseType, 'base-type');
                });
                console.log(toggle, toggleInside, 'here-togle-inside');
              }
            });
          }
        });
      });
      //console.log(finalArray, 'finalll-array');
      return finalArray;
    },

    changeBaseType(mapValue, toggle, baseTypeId, toggleId) {
      // console.log('toggle', toggle);
      // console.log('baseTypeId', baseTypeId);
      // console.log('toggleId', toggleId);

      const finalLayer = _.find(toggle.baseTypesId, (n) => {
        const obj = n.toggleId === toggleId;
        return obj;
      });
      // console.log('finalLayer', finalLayer);
      if (finalLayer == undefined) {
        console.log('data is not related to this toggle');
        toggle.baseTypesId.push({ baseTypeId: baseTypeId, toggleId: toggleId });
      } else {
        console.log('data is there just update this');
        finalLayer.baseTypeId = baseTypeId;
      }
      this.$forceUpdate();
      // console.log('final toggle', toggle.baseTypesId);
    },
    toggleEmbroidery(layer, toggle) {
      const finalLayer = _.find(layer.toggles, (n) => {
        const obj = n === toggle;
        return obj;
      });
      finalLayer.showEmbroidery = !finalLayer.showEmbroidery;
      this.$forceUpdate();
    },

    assignLayerValueToColorMapping(incomingLayer) {
      var finalArray = [];
      _.map(this.categories, (category) => {
        _.map(category.layers, (layer) => {
          var data = this.checkLayerAlreadyExistsInColorMapping(incomingLayer.colorMapping, layer);
          if (data.result) {
            finalArray.push(data.obj);
          } else {
            if (layer.typeOfLayer == 'Image') {
              var temp = {};
              temp.id = '';
              temp.layerId = layer.id;
              temp.name = layer.name;
              if (temp.baseTypesId == undefined) {
                temp.baseTypesId = [];
              }
              finalArray.push(temp);
            }
          }
        });
      });
      return finalArray;
    },

    checkLayerAlreadyExistsInColorMapping(colorMapping, layer) {
      var temp = {};
      const finalLayer = _.find(colorMapping, (n) => {
        const obj = n.layerId === layer.id;
        return obj;
      });
      if (finalLayer) {
        temp.obj = finalLayer;
        temp.result = true;
      } else {
        temp.obj = finalLayer;
        temp.result = false;
      }
      return temp;
    },
    async updateData(title) {
      this.dialog = false;
      this.productData.customizationStatus = this.selectedStatus;
      this.productData.categories = this.categories;
      const result = await service.updateProduct(this.$route.params.id, this.productData);
      // if (result.status === 200) {
      //   await this.$toasted.show("data saved!.", {
      //     theme: "bubble",
      //     position: "bottom-center",
      //     duration: 500,
      //   });
      //   await this.$router.push({
      //     name: "Step4",
      //     params: { id: result.data._id },
      //   });
      if (result.status === 200) {
        /* eslint-disable */
        if (title == 'Next') {
          await this.$toasted.show('data saved!.', {
            theme: 'bubble',
            position: 'bottom-center',
            duration: 500,
          });
          await this.$router.push({
            name: 'Step4',
            params: { id: result.data._id },
          });
        } else {
          await this.$toasted.show('data saved!.', {
            theme: 'bubble',
            position: 'bottom-center',
            duration: 500,
          });
        }

        this.categories = result.data.categories;
        this.productData = result.data;
      }
    },
    async getData() {
      const result = await service.getProduct(this.$route.params.id);
      this.customization = result.data;
      this.categories = result.data.categories;
      this.hideAllToggle();
      this.gender = result.data.gender;
      this.embroideries = result.data.embroideries;
      this.selectedStatus = result.data.customizationStatus;
      this.productData = result.data;
      this.$store.commit('setCustomization', this.customization);
      if (result.data && result.data.product && result.data.product.deliveryDetails.length > 0) {
        _.each(result.data.product.deliveryDetails, (n) => {
          if (n.mrp > 0) {
            this.$store.commit('setProductPrice', n.mrp);
            return false;
          }
        });
      }
      this.customizationsLoaded = true;
    },
    hideAllToggle() {
      _.each(this.categories, (category) => {
        _.each(category.layers, (layer) => {
          _.each(layer.toggles, (toggle) => {
            toggle.show = false;
          });
        });
      });
    },
    getCategoryNameFromLayerId(layerId) {
      var catName = '';
      _.each(this.categories, (category) => {
        _.each(category.layers, (layer) => {
          if (layer.id == layerId) {
            catName = category.name;
          }
        });
      });
      return catName;
    },
    getGroupName(group) {
      var input = group;
      var fields = input.split('_');
      if (fields.length > 0) {
        input = fields[0];
      }
      return input;
    },
    goBack() {
      history.go(-1);
    },
  },
  async mounted() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
}
.model-wrapper {
  position: fixed;
  left: 50%;
}
.next-button-section {
  margin: 30px 0;
}
.image-upload-section {
  padding: 20px;
}
.sub-text {
  font-size: 12px;
  color: #999;
}
.category-tree {
  position: fixed;
  zoom: 0.7;
  top: 10px;
}
.embroidery-section {
  padding: 20px;
  background: #e0e1ff;
  border-radius: 20px;
}
.category-section {
  padding: 20px;
  background: #e0eeff;
  border-radius: 20px;
}
.layer-section {
  background: #e0fcfe;
  border-radius: 20px;
  padding: 20px;
}
.layer-section + .layer-section {
  margin-top: 20px;
}
.toggle-section {
  background: #fee0e0;
  border-radius: 20px;
  padding: 20px;
}

.delete-button-holder {
  position: relative;
}
.delete-button {
  position: absolute;
  zoom: 0.7;
  left: -67px;
  top: 19px;
}
.show-button {
  zoom: 0.7;
}
.margin-left-1 {
  margin: 10px 0px 10px 80px;
}
.pull-right {
  float: right;
}
.padding-10 {
  padding: 10px;
}
.custom-range {
  margin-left: 30px;
  width: 220px;
}
.category {
  margin-top: 80px;
  .model-name {
    font-size: 15px;
  }
  .category-name {
    font-size: 32px;
  }
  .layer {
    margin-top: 20px;
    select {
      font-size: 15px;
    }
    font-size: 26px;
    margin-left: 20px;
    .toggle {
      .toggle-name {
        margin-top: 10px;
      }
      font-size: 20px;
      margin-left: 20px;
      .images-upload {
        font-size: 16px;
        margin-left: 20px;
        .image-upload-section {
          font-size: 14px;
          margin-left: 20px;
          width: 100px;
          height: 100px;
          border: 1px solid #ccc;
          display: inline-block;
          text-align: center;
          line-height: 100px;
        }
      }
    }
  }
}
.select-color {
  border: 1px solid rgb(0, 0, 0) !important;
  // padding-left: 4px;
}
.go-back {
  cursor: pointer !important;
}
.model-fixed {
  position: fixed;
  left: 50%;
  top: 10%;
  transform: translateX(-55%);
  transform: translateY(-5%);
  transform: scaleY(0.8);
  transform: scale(0.75);
  padding-top: 4em;
  max-height: 400px !important;
  max-width: 750px !important;
}
@media only screen and (max-height: 679px) {
  .model-fixed {
    position: fixed;
    left: 50%;
    top: 10%;
    transform: translateX(-55%);
    transform: translateY(-5%);
    transform: scaleY(0.6);
    transform: scale(0.6);
    padding: 3em;
    max-height: 280px !important;
    max-width: 720px !important;
  }
}
</style>
